import LocomotiveScroll from 'locomotive-scroll'

window.addEventListener('load', function() {
    // Check if all images are loaded
    const images = document.images;
    const totalImages = images.length;
    let imagesLoaded = 0;

    for (let img of images) {
        if (img.complete) {
            imagesLoaded++;
        } else {
            img.addEventListener('load', imageLoaded);
            img.addEventListener('error', imageLoaded); // handle broken images
        }
    }

    // Function to call when an image is loaded or on error
    function imageLoaded() {
        imagesLoaded++;
        // Check if all images are loaded
        if (imagesLoaded === totalImages) {
            fadeInDocument();
        }
    }

    // If all images are cached and 'load' event not triggered
    if(imagesLoaded === totalImages) {
      fadeInDocument();
    }

    // Function to fade in the document
    function fadeInDocument() {
        document.body.style.opacity = '1'; // Or target your specific container
    }
});

document.addEventListener('mousemove', function(e) {
  var cursor = document.getElementById('customCursor');
  cursor.style.transform = `translate(${e.pageX - 10}px, ${e.pageY - 10}px)`;
});

document.querySelectorAll('button, a').forEach(element => {
  element.addEventListener('mouseenter', addHoverClass);
  element.addEventListener('mouseleave', removeHoverClass);
});

function displayP3ToRGB(p3) {
    // Simplified approximation: Direct mapping from Display P3 to sRGB space
    const matches = p3.match(/display-p3 ([\d.]+) ([\d.]+) ([\d.]+)(?: \/ ([\d.]+))?/);
    if (!matches) return null; // Return null if the format does not match

    let [_, r, g, b, a] = matches.map(Number);
    // Convert from 0-1 range to 0-255 range for RGB, clamp values just in case
    r = Math.min(255, Math.round(r * 255));
    g = Math.min(255, Math.round(g * 255));
    b = Math.min(255, Math.round(b * 255));
    a = a ? Math.min(1, a).toFixed(2) : 1; // Ensure alpha is between 0 and 1

    return `rgba(${r}, ${g}, ${b}, ${a})`;
}

function findBackgroundColor(element) {
    if (!element || element.tagName === 'BODY') {
        return 'rgb(255, 255, 255)'; // Default to white if no background color is found
    }

    let bgColor = getComputedStyle(element).backgroundColor;
    // Check and convert Display P3 color if present
    if (bgColor.startsWith('color(display-p3')) {
        bgColor = displayP3ToRGB(bgColor);
    }

    // Recursive check if transparent or not set
    if (bgColor === 'transparent' || bgColor.includes('rgba') && bgColor.endsWith(', 0)')) {
        return findBackgroundColor(element.parentElement);
    }

    return bgColor;
}

function getLightness(rgb) {
    if (!rgb || rgb === 'transparent' || rgb.includes('rgba') && parseFloat(rgb.split(',')[3]) < 0.1) {
        return null;
    }
    let [r, g, b] = rgb.match(/\d+/g).map(Number);
    r /= 255, g /= 255, b /= 255;
    let max = Math.max(r, g, b), min = Math.min(r, g, b);
    return (max + min) / 2;
}

document.body.addEventListener('mouseover', function(e) {
    const bgColor = findBackgroundColor(e.target);
    const lightness = getLightness(bgColor);
    const cursor = document.getElementById('customCursor');
    console.log(bgColor);
    
    if (lightness !== null) {
        if (lightness < 0.5) {
            cursor.classList.add('light');
            cursor.classList.remove('dark');
        } else {
            cursor.classList.add('dark');
            cursor.classList.remove('light');
        }
    }
});

document.querySelectorAll('.vendor-list__container__item__link').forEach(element => {
  element.addEventListener('mouseenter', function() {
    var dataValue = this.getAttribute('data-local');
    var dataDisplayDiv = document.getElementById('dataDisplay');
    dataDisplayDiv.innerHTML = dataValue;
    dataDisplayDiv.classList.add('visible');
  });
  
  element.addEventListener('mouseleave', function() {
    document.getElementById('dataDisplay').classList.remove('visible');
  });
});

function addHoverClass() {
  document.getElementById('customCursor').classList.add('hover-effect');
}

function removeHoverClass() {
  document.getElementById('customCursor').classList.remove('hover-effect');
}

document.addEventListener('DOMContentLoaded', function() {
  // Add loaded class to body
  // document.querySelector('body').classList.add('loaded')

  // Set root styles
  const root = document.documentElement;
  const body = document.body;
  const backgroundColor = body?.dataset.bgcolor || '#FFFFFF'; // Default white
  const textColor = body?.dataset.textcolor || '#000000'; // Default black
  root.style.setProperty('--backgroundColor', backgroundColor);
  root.style.setProperty('--textColor', textColor);

  const hexToP3 = (string) => {
    const aRgbHex = string.replace("#", "").match(/.{1,2}/g);
    const aRgb = [
        (parseInt(aRgbHex[0], 16) / 255).toFixed(2),
        (parseInt(aRgbHex[1], 16) / 255).toFixed(2),
        (parseInt(aRgbHex[2], 16) / 255).toFixed(2),
    ];
    return `color(display-p3 ${aRgb.join(" ")})`;
  }

  root.style.setProperty('--backgroundColorP3', hexToP3(backgroundColor));
  root.style.setProperty('--textColorP3', hexToP3(textColor));

  // Navigation toggle functionality
  var triggerDiv = document.getElementsByClassName('nav-button')[0];
  var targetDiv = document.getElementsByClassName('nav-project-drawer')[0];

  triggerDiv.addEventListener('click', function() {
    if (targetDiv.classList.contains('active')) {
      targetDiv.classList.remove('active');
      triggerDiv.classList.remove('active');
    } else {
      triggerDiv.classList.add('active');
      targetDiv.classList.add('active');
    }
  });

  const scroll = new LocomotiveScroll({
    el: document.querySelector('[data-scroll-container]'),
    smooth: true,
    direction: 'horizontal',
    inertia: 0.6,
    getSpeed: true,
    getDirection: true,
    gestureDirection: 'both',
    touchMultiplier: 3,
    tablet: {
      smooth: true,
      direction: 'horizontal',
      breakpoint: 768
    },
    smartphone: {
      smooth: true,
      direction: 'vertical',
      breakpoint: 480
    }
  })

  function checkContentSize () {
    const carouselWidth = carousel.offsetWidth
    const aboutWidth = about.offsetWidth
    const contentWidth = carouselWidth + aboutWidth

    if (windowWidth > contentWidth && !carousel.classList.contains('full-width')) {
      carousel.classList.add('full-width')
    } else if (windowWidth <= contentWidth && carousel.classList.contains('full-width')) {
      carousel.classList.remove('full-width')
    }
  }

  function updateScrollDirection () {
    const scrollDirection = html.getAttribute('data-scroll-direction')
    const isVertical = scrollDirection === 'vertical'
    const isHorizontal = scrollDirection === 'horizontal'
    const shouldChangeToHorizontal = isVertical && windowWidth > breakpoint
    const shouldChangeToVertical = isHorizontal && windowWidth <= breakpoint

    if (shouldChangeToHorizontal || shouldChangeToVertical) {
      scroll.destroy()
      scroll.init()
    }
    scroll.update()
  }

  function handleResize () {
    windowWidth = window.innerWidth
    updateScrollDirection()
    updateProjectDescriptionDivs()
    checkContentSize()
    scroll.update()
  }

  // Debounce function
  function debounce (func, wait = 150) {
    let timeout
    return function () {
      const context = this; const args = arguments
      clearTimeout(timeout)
      timeout = setTimeout(() => func.apply(context, args), wait)
    }
  }

function adjustImagesScaleToFitParent() {
    const images = document.querySelectorAll('.project-card__image'); // Select all scalable images
    
    images.forEach((image) => {
        const parentContainer = image.parentElement; // Get the image's parent container
        
        // Scale the parent container instead of the image
        scaleParentContainer(parentContainer);
    });

    function scaleParentContainer(parent) {
        const parentHeight = parent.offsetHeight; // Get current height of the parent
        
        // Define the start and end points for the interpolation
        const startScale = 2.5; // Scale factor at 500px
        const endScale = 1.4; // Scale factor at 1050px
        const startHeight = 500;
        const endHeight = 800;
        
        // Calculate scale factor based on the parent's height
        const scaleFactor = startScale + (endScale - startScale) * (parentHeight - startHeight) / (endHeight - startHeight);
        
        // Clamp the scale factor to the min and max values to prevent scaling beyond the intended range
        const clampedScaleFactor = Math.max(Math.min(scaleFactor, startScale), endScale);
        
        // Apply the scale to the parent container
        parent.style.transform = `scale(${clampedScaleFactor})`;
    }
}

  // Initial adjustment for all images
  // qaàqjustImagesScaleToFitParent();    q11q111tggttg66
  window.addEventListener('resize', debounce(handleResize), adjustImagesScaleToFitParent)

  updateProjectDescriptionDivs()
  updateScrollDirection()
  checkContentSize()
  scroll.init()

  

  

});
